.quill {
}

.ql-container {
}

.ql-editor {
	font-family: poppins, serif;
	font-size: 14px;
	min-height: 200px;
}

.ql-editor img {
	max-width: 100%;
}

.ql-toolbar {
	background-color: #eeeeec;
	opacity: .9;
	position: sticky;
	top: 0;
	z-index: 1000;
}
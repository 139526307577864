html, body, #root {
	margin: 0;
	padding: 0;
	font-family: poppins, sans-serif;
	/* overflow: hidden; */
	height: 100%;
}

#root {
	display: flex;
	flex-direction: column;
}

a {
	color: inherit;
}

table, th, td {
    border: 1px solid lightgray;
    border-collapse: collapse;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.6) inset !important;
}

.hidden{
  transition : 0.5s;
  display : none;
}

@keyframes animation-golf-rotate {
  0% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(360deg);
    transform-origin: 50% 50%;
  }
}

@keyframes animation-golf-rotate-rev {
  0% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }
  50% {
    transform: rotate(-180deg);
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(-350deg);
    transform-origin: 50% 50%;
  }
}

@keyframes animation-golf-progress {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}
